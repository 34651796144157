import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'
const initialState = {};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            state.username = action.payload.username;
            state.password = action.payload.password;
            state.loading = true;
            state.failed = false;
        },
        success(state, action) {
            if (action.payload?.token) {
                Cookies.set('X-Auth', action.payload.token, { expires: 10000 });
                const expirationTime = new Date(Date.now() + (24 * 60 * 60 * 1000));

                Cookies.set('Exp-Time', expirationTime.getTime(), { expires: 10000 });
            }
            return { ...action.payload };
        },
        failed(state, action) {
            state.loading = false;
            state.failed = true;
        },
        logout(state) {
            Cookies.remove('X-Auth');
            Cookies.remove('X-Auth-Tool');
            return { loggedout: true };
        },
        checkAuth(state) {
            state.loading = true;
        },
        toolSuccess(state, action) {
            Cookies.set('X-Auth-Tool', action.payload.token, { expires: 10000 });
            return { ...state, tool_token: action.payload.token }
        },
        toolFailed(state, action) {
            // state.loading = false;
            // state.failed = true;
        },
    },
});

export const { login, success, failed, checkAuth, logout, toolSuccess, toolFailed } = AuthSlice.actions;

export default AuthSlice.reducer;
