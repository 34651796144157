import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const EvaluationSlice = createSlice({
    name: 'evaluation',
    initialState,
    reducers: {
        request(state) {
            state.failed = false;
        },
        success(state, action) {
            return { result: [...action.payload] ,loading:false,failed:false };
        },
        failed(state) {
            state.failed = true;
            state.loading = false;
        },
        add(state, action) {
            state.pending = action.payload;
            state.loading = true;
        }
    },
});

export const { request, success, failed, add } = EvaluationSlice.actions;

export default EvaluationSlice.reducer;
