// ** Logo
import logo from '@src/assets/images/logo/logo2.png'
import loader from '@src/assets/images/loader/loader_rocket.gif'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader position-relative'>
      <img className='fallback-logo mt-1' width='120' src={logo} alt='logo' />
      {/* <img className='fallback-logo' src={loader} alt='logo' width='300' height='300' /> */}
      <div className='loading mt-0'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
