import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'
const initialState = {};

const SignupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        signup(state, action) {
            state.username = action.payload.username;
            state.email = action.payload.email;
            state.password = action.payload.password;
            state.confirmpassword = action.payload.confirmpassword;
            state.loading = true;
            state.failed = false;
        },
        signupsuccess(state, action) {
            if (action.payload?.token) {
                Cookies.set('X-Auth', action.payload.token, { expires: 10000 });
                const expirationTime = new Date(Date.now() + (24 * 60 * 60 * 1000));

                Cookies.set('Exp-Time', expirationTime.getTime(), { expires: 10000 });
            }
            return { ...action.payload };
        },
        signupfailed(state, action) {
            state.loading = false;
            state.failed = true;
        }
    },
});

export const { signup, signupsuccess, signupfailed } = SignupSlice.actions;

export default SignupSlice.reducer;
