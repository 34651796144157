import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  failed: false,


  compititorLsiList: [],
  compititorLsiListLoading: false,
  updateWorkflowLoading: false,
  createWorkflowFormData: {},
  updateAgentLoading: false,
  createAgentFormData: {},
  updateAgentFormData: {},

  filterworkflows: {
    data: [],
  },
  history: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  stats: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  onpageseo: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  agenthistory: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  manageragenthistory: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  kickoffhistory: {
    data: {},
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  feedbackhistory: {
    data: {},
    sidebarLoader: false,
    title: '',
    agentRecords: [],
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
};

const ToolSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },

    setCompititorLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setContentSelectedLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setCompititorLsiListLoading(state, action) {
      state.compititorLsiListLoading = action.payload;
    },
    setUpdateWorkflowLoading(state, action) {
      state.updateWorkflowLoading = action.payload;
    },
    setcreateWorkflowFormData(state, action) {
      state.createWorkflowFormData = action.payload;
    },
    setUpdateAgentLoading(state, action) {
      state.updateAgentLoading = action.payload;
    },
    setcreateAgentFormData(state, action) {
      state.createAgentFormData = action.payload;
    },
    setupdateAgentFormData(state, action) {
      state.updateAgentFormData = action.payload;
    },
    setFilterWorkflows(state, action) {
      state.filterworkflows = action.payload;
    },
    setHistory(state, action) {
      state.history = action.payload;
    },
    setAgentHistory(state, action) {
      state.agenthistory = action.payload;
    },
    setManagerAgentHistory(state, action) {
      state.manageragenthistory = action.payload;
    },
    setKickOffHistory(state, action) {
      state.kickoffhistory = action.payload;
    },
    setFeedbackHistory(state, action) {
      state.feedbackhistory = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setOnPageSeo(state, action) {
      state.onpageseo = action.payload;
    },

  },
});

export const {
  request,
  success,
  setCompititorLsiList,
  setContentSelectedLsiList,
  setCompititorLsiListLoading,
  setUpdateWorkflowLoading,
  setcreateWorkflowFormData,
  setUpdateAgentLoading,
  setcreateAgentFormData,
  setupdateAgentFormData,
  setFilterWorkflows,
  setHistory,
  setAgentHistory,
  setManagerAgentHistory,
  setKickOffHistory,
  setFeedbackHistory,
  setStats,
  setOnPageSeo
} = ToolSlice.actions;

export default ToolSlice.reducer;
