// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import { combineReducers } from 'redux'
import employees from '../views/Employees/EmployeeSlice'
import evaluation from '../views/Employees/EvaluationSlice'
import authorization from '../views/AuthSlice'
import designations from '../views/Employees/DesignationSlice'
import workflow from '../views/Workflow/Store/ToolSlice'
import signup from '../views/SignupSlice'
const rootReducer = {
  auth,
  navbar,
  layout,
  employees,
  designations,
  authorization,
  signup,
  evaluation,
  workflow
}

export default combineReducers(rootReducer)
