import Cookies from 'js-cookie'
import axios from 'axios';
export const loginRequest = async (username, password) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/agentic/login`, { email: username, password: password }).then(function (response) {
            resolve(response.data);
        }).catch(function (e) {
            reject(e);
        })
    })
}
export const autoAuth = async () => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/agentic/user`, {
            headers: {
                Authorization: "Bearer " + Cookies.get("X-Auth")
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}
export const checkAuth = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/login/check`).then(function (response) {

        })
    });
}
export const toolAuthToken = async (username, password) => {
    return new Promise((resolve, reject) => {

        const formData = new FormData();

        formData.append('username', username);
        formData.append('password', password);

        return axios.post(`${process.env.REACT_APP_PYTHON_CONTENT_CREATION_API_URL}/api/token/generate`, formData)
            .then(function (response) {
                resolve(response.data);
            }).catch(function () {
                reject();
            })
    })
}
export const signupRequest = async (username, email, password) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/agentic/register`, { name: username, password: password, email: email }).then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}
export const verifyUserRequest = async (id, verify) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/agentic/verify-user`, { user_id: id, is_verify: verify ? 1 : 0 }).then(function (response) {
            resolve(response.data);
        }).catch(function () {
            reject();
        })
    })
}