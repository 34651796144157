import { login, failed, success, checkAuth, toolSuccess, toolFailed } from '../../views/AuthSlice'
import { signup, signupfailed, signupsuccess } from '../../views/SignupSlice'
import { call, put, select, take, takeLatest, takeEvery, delay } from 'redux-saga/effects'
import { autoAuth, loginRequest, signupRequest, toolAuthToken } from '../../api/auth'
import Cookies from 'js-cookie'


const getAuthorization = state => state.authorization;
const getSignUp = state => state.signup;
function* validateToken(token) {
    const auth = yield select(getAuthorization);
}
function* getToken() {
    const auth = yield select(getAuthorization);
    if (!auth.token) {
        try {
            const result = yield call(autoAuth);
            if (result) {
                yield put({ type: success.toString(), payload: { token: Cookies.get("X-Auth"), user: { employee: result } } });
            } else {
                yield put({ type: failed.toString() });
            }

        } catch (error) {
            yield put({ type: failed.toString() });
        }
    }
}
export function* autoAuthSaga() {
    yield takeEvery(checkAuth.toString(), getToken);
}
function* authorize() {
    const state = yield select(getAuthorization);
    const { username, password } = state;
    try {
        const result = yield call(loginRequest, username, password);
        yield put({ type: success.toString(), payload: result });
    } catch (error) {
        yield put({ type: failed.toString() });
    }

}
function* signupauth() {
    const state = yield select(getSignUp);
    const { username, email, password, confirmpassword } = state;
    try {
        const result = yield call(signupRequest, username, email, password);
        yield put({ type: signupsuccess.toString(), payload: result });
    } catch (error) {
        yield put({ type: signupfailed.toString() });
    }

}

function* authorizeCreateContentTool() {
    const state = yield select(getAuthorization);
    const { username, password } = state;
    try {
        const result = yield call(toolAuthToken, username, password);
        yield put({ type: toolSuccess.toString(), payload: result });
    } catch (error) {
        yield put({ type: toolFailed.toString() });
    }
}
function* authSaga() {
    yield takeLatest(login.toString(), authorize);
    // yield takeLatest(login.toString(), authorizeCreateContentTool);
    yield takeLatest(signup.toString(), signupauth);
}
export default authSaga;