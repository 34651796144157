import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    designations : [],
    roles: []
};

const DesignationSlice = createSlice({
    name: 'designations',
    initialState,
    reducers: {
        request(state) {
            state.loading = true;
        },
        success(state, action) {
          
            return  {
                loading: false,
                designations: action.payload.designations,
                roles : action.payload.roles
            }
        }
    },
});

export const { request, success } = DesignationSlice.actions;

export default DesignationSlice.reducer;
